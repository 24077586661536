/*
 * Copyright 2019. Quantum Corporation. All Rights Reserved.
 * StorNext is either a trademark or registered trademark of
 * Quantum Corporation in the US and/or other countries.
 *
 */

import axios from 'axios';
import store from '@/store';

export default {
    validateEmail: (parameters: { email: string|string[]; auth: string|string[] }) =>
        axios.post(store.getters['siteConfigState/getBackend'] + '/validate-email',
                   parameters,
                   {headers:
                    {'Content-Type': 'application/json'}}),
};
