

















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ValidateEmailApi from '@/services/api/ValidateEmail';

@Component
class ValidateEmail extends Vue {

    public email: string;
    public dialog: string;
    public errorMsg: string;

    public constructor() {
        super();
        this.email = '';
        this.dialog = 'validating';
        this.errorMsg = 'This email address may have already been activated, or it may not have been registered.' +
                '<br>' +
                'Please try registering again or use the \'forgot my password\' link.';
    }

    public mounted() {
        if (!this.$route.query.email) {
            this.errorMsg = 'Invalid email address passed in.';
            this.dialog = 'error';
            return;
        }
        if (!this.$route.query.auth) {
            this.errorMsg = 'Invalid authorization passed in.';
            this.dialog = 'error';
            return;
        }
        // Decode the email address before sending it via JSON
        this.email = decodeURIComponent(this.$route.query.email.toString());
        this.runValidation(this.email, this.$route.query.auth.toString());
    }

    private runValidation(email: string, auth: string) {

        ValidateEmailApi.validateEmail({email, auth})
            .then(() => {
                this.dialog = 'validated';
            })
            .catch(() => {
                this.dialog = 'error';
            });
    }
}

export default ValidateEmail;
